const navbarThemeToggle = () => {
  // const $navbar = $('.navbar');
  // const $main = $('.main');
  // const $height = 65;
  const $header = $("header");
  const header = $("nav.navbar");
  const sticky = header.offset().top;

  if (window.pageYOffset > sticky) {
    header.addClass("sticky py-4");
    $header.addClass("sticky-header");
  }
  if (window.pageYOffset < 51) {
    header.removeClass("sticky py-4");
    $header.removeClass("sticky-header");
  }

  // if (window.pageYOffset > $height) {
  //   $navbar.removeClass('py-4');
  //   $main.addClass('main-top');
  // }

  // if (window.pageYOffset < $height + 1) {
  //   $navbar.addClass('py-4');
  //   $main.removeClass('main-top');
  // }
};

const lazyImage = () => {
  //   document.addEventListener("DOMContentLoaded", function() {
  const lazyloadImages = document.querySelectorAll("img.load-lazy");
  let lazyloadThrottleTimeout;

  function lazyload() {
    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(() => {
      const scrollTop = window.pageYOffset;
      lazyloadImages.forEach(img => {
        if (img.offsetTop < window.innerHeight + scrollTop) {
          img.src = img.dataset.src;
          img.classList.remove("lazy");
        }
      });
      if (lazyloadImages.length == 0) {
        document.removeEventListener("scroll", lazyload);
        window.removeEventListener("resize", lazyload);
        window.removeEventListener("orientationChange", lazyload);
      }
    }, 20);
  }

  document.addEventListener("scroll", lazyload);
  window.addEventListener("resize", lazyload);
  window.addEventListener("orientationChange", lazyload);
  //   });
};

const sticky = () => {
  const $navbar = $(".sticky-top");
  const $sticky = $navbar.offset().top;

  if (window.pageYOffset > 684) {
    $navbar.addClass("sticky");
  }

  if (window.pageYOffset < 685) {
    $navbar.removeClass("sticky");
  }

  // if (window.pageYOffset > $sticky) {
  //   $navbar.addClass("sticky")
  // } else if (window.pageYOffset < 685) {
  //   $navbar.removeClass("sticky");
  // }
};

const anchorOnScroll = () => {
  const $htmlbody = $("html, body");
  const $scrollup = $("#scroll-up");

  const box = document.querySelector(".navbar");
  const height = box.clientHeight - 18;

  function onscroll() {
    const $section = $(`[data-anchor="${location.hash}"]`);

    if ($section.length) {
      console.log(`offset top ${$section.offset().top} ${height}`);

      $htmlbody.animate(
        {
          scrollTop: $section.offset().top - height
        },
        700
      );
    }
  }

  $(window)
    .on("hashchange load", onscroll)
    .on("scroll", function() {
      if ($(this).scrollTop() > 100) {
        $scrollup.fadeIn();
      } else {
        $scrollup.fadeOut();
      }
    });

  $scrollup.on("click", e => {
    e.preventDefault();
    $htmlbody.animate(
      {
        scrollTop: 0
      },
      600
    );
  });
};
const showmoreArticles = () => {
  const $newsArticles = $("#news-articles");
  let page = 2;

  $("#news-articles-more").on("click", async e => {
    const $element = $(e.target);
    const articles = await $.get($element.data("source"), { page }).promise();

    e.preventDefault();

    $element
      .html('<i class="fas fa-spinner fa-spin"></i>')
      .attr("disabled", true);

    if (articles) {
      $newsArticles.append(articles);
      page++;
      $element.attr("disabled", false).html("Load More");
    } else {
      $element.removeClass("btn-secondary").html("No More");
    }
  });
};

const mediaQuery = () => {
  const smallScreen = window.matchMedia("(max-width: 767px)");
  const owlName = $('[data-toggle="owl-carousel-mobile"]');

  if (smallScreen.matches) {
    // If media query matches
    owlCarousel(owlName);
  } else {
    owlName.trigger("destroy.owl.carousel");
    owlName.css("display", "none");
  }

  // mediaQuery(smallScreen) // Call listener function at run time
  smallScreen.addListener(mediaQuery); // Attach listener function on state changes
};

const tabList = () => {
  // Hide all the elements in the DOM that have a class of "box"
  $(".tab-pane").hide();
  const clickThis = $('[data-toggle="tab"]');

  // Make sure all the elements with a class of "clickme" are visible and bound
  // with a click event to toggle the "box" state
  clickThis.each(function() {
    $(this)
      .show(0)
      .on("click", function(e) {
        // This is only needed if your using an anchor to target the "box" elements
        e.preventDefault();

        // Find the next "box" element in the DOM
        $(this)
          .next(".tab-pane")
          .slideToggle("fast", function() {
            $(this)
              .prev()
              .html($(this).is(":visible") ? "Hide" : "Show");
          });
      });
  });
};

const owlCarousel = dataName => {
  dataName.each(function() {
    const $that = $(this);

    // Declare Carousel jquery object
    const owl = $that;

    // Fired before current slide change
    // owl.on('change.owl.carousel', function(event) {
    //   var $currentItem = $('.owl-item', owl).eq(event.item.index);
    //   var $elemsToanim = $currentItem.find("[data-animation-out]");
    //   setAnimation ($elemsToanim, 'out');
    // });

    // Fired after current slide has been changed
    owl.on("changed.owl.carousel", event => {
      const $currentItem = $(".owl-item", owl).eq(event.item.index);
      const $elemsToanim = $currentItem.find("[data-animation-in]");
      setAnimation($elemsToanim, "in");
    });

    $that.owlCarousel($that.data());
  });
};

const setAnimation = (_elem, _InOut) => {
  // Store all animationend event name in a string.
  // cf animate.css documentation
  const animationEndEvent =
    "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";

  _elem.each(function() {
    const $elem = $(this);
    const $animationType = `animated ${$elem.data(`animation-${_InOut}`)}`;

    $elem.addClass($animationType).one(animationEndEvent, () => {
      $elem.removeClass($animationType); // remove animate.css Class at the end of the animations
    });
  });
};

const searchbar = () => {
  const $navbar = $(".navbar");
  const $navSearch = $navbar.find(".nav-search");
  const $navSearchForm = $navSearch.find("form");
  const $navSearchInput = $navSearchForm.find("input");

  $navSearch.find("button").on("click", e => {
    e.preventDefault();

    if ($navSearchInput.val().length) {
      // $navSearchInput.val($navSearchInput.val().toLowerCase());
      $navSearchForm.submit();
    } else {
      $navSearchInput.focus();
      $navSearch.toggleClass("open");
      $navSearchInput.val("");
    }
  });
};

const navbarCollapseOpaque = () => {
  const $navbar = $(".navbar");

  $(".navbar-toggler").on("click", e => {
    $navbar.toggleClass("navbar-opaque");
  });
};

const magnificPopUp = () => {
  $('[data-toggle="magnific-popup"]').each(function() {
    const $that = $(this);
    $that.magnificPopup($that.data());
  });
};

const searchShow = () => {
  const input = $("#search-input");
  const searchBtn = $("#search-btn");
  const square = $(".square");
  input.blur();

  const expand = () => {
    searchBtn.toggleClass("close");
    input.toggleClass("square");
    $(".square").focus();
  };

  searchBtn.on("click", expand);
};

const filterMarkets = () => {
  const $projectCards = $("#projects .project-item");
  const $projectFilterItem = $("#project-filter-markets .dropdown-item");
  const $projectFilterToggleHolder = $(
    "#project-filter-markets .dropdown-toggle .holder"
  );

  $projectFilterItem.on("click", function(e) {
    const $that = $(this);

    e.preventDefault();

    $projectFilterToggleHolder.html($that.data("markets"));
    $projectFilterItem.filter(".active").removeClass("active");
    $that.addClass("active");

    if ($that.data("markets") === "All") {
      $projectCards.each(function() {
        $(this).removeClass("hide");
      });
    } else {
      $projectCards.each(function() {
        const $card = $(this);
        const item = $card.data("markets");
        const result = $that.data("markets");

        $(this).removeClass("hide");

        if (!item.includes(result)) {
          $card.addClass("hide");
        }
      });
    }
  });
};

const filterExpertise = () => {
  const $projectCards = $("#projects .project-item");
  const $projectFilterItem = $(
    "#project-filter-expertise .dropdown-item, #project-filter-markets .dropdown-item"
  );
  // const $projectFilterItemMarket = $("#project-filter-markets .dropdown-item");
  const $projectFilterToggleHolderE = $(
    "#project-filter-expertise .dropdown-toggle .holder"
  );

  const $projectFilterToggleHolderM = $(
    "#project-filter-markets .dropdown-toggle .holder"
  );

  $projectFilterItem.on("click", function(e) {
    const $that = $(this);

    e.preventDefault();

    $projectFilterToggleHolderE.html($that.data("expertise"));
    $projectFilterToggleHolderM.html($that.data("markets"));
    $projectFilterItem.filter(".active").removeClass("active");
    $that.addClass("active");

    if ($that.data("expertise") === "All" || $that.data("markets") === "All") {
      $projectCards.each(function() {
        $(this).removeClass("hide");
        console.log($that);
      });
    } else {
      $projectCards.each(function() {
        const $card = $(this);
        const itemE = $card.data("expertise");
        const resultE = $that.data("expertise");
        const itemM = $card.data("markets");
        const resultM = $that.data("markets");

        $(this).removeClass("hide");

        if (!itemE.includes(resultE) && !itemM.includes(resultM)) {
          $card.addClass("hide");
        }
      });
    }
  });
};

const scrollToView = () => {
  $('a[data-toggle="tab"]').on("shown.bs.tab", function(e) {
    e.target; // newly activated tab
    e.relatedTarget; // previous active tab
    const $this = $(this);
    const $tabPane = $this.attr("href");
    const $anchor = $('[data-anchor="#tile-projects"]');
    $("html, body").animate(
      {
        scrollTop: $anchor.offset().top
      },
      1000
    );
  });

  //   $('#benefits').on('shown.bs.collapse', function () {
  //     $('html, body').animate({
  //        scrollTop: $("#benefits").offset().top
  //     }, 1000);
  // });
};

const sectionHeight = () => {
  const case1_Height = $("#caseImage");
  const case2_Height = $(".container-case-2");
  const total = case2_Height.innerHeight();

  case1_Height.css("height", `${total}px`);
  case2_Height.css("height", `${total}px`);
};

export default () => {
  anchorOnScroll();
  navbarCollapseOpaque();
  navbarThemeToggle();
  magnificPopUp();
  scrollToView();
  searchbar();
  owlCarousel($('[data-toggle="owl-carousel"]'));
  // filterMarkets();
  filterExpertise();
  // skrollRoll();
  // searchShow();
  // tabList();
  // sticky();
  // sectionHeight();
  mediaQuery();
  lazyImage();

  $(window).on("scroll", navbarThemeToggle);

  const owl = $(".owl-carousel");
  // disable scroll
  owl.on("drag.owl.carousel", event => {
    document.ontouchmove = function(e) {
      e.preventDefault();
    };
    $("body").css("overflow", "hidden");
  });

  // enable scroll
  owl.on("dragged.owl.carousel", event => {
    document.ontouchmove = function(e) {
      return true;
    };
    $("body").css("overflow", "auto");
  });

  // $(window).on('resize', mediaQuery);

  // hero caraousel - display the first item
  $($(".hero .hero-item")[0]).addClass("active");
  $(".carousel").carousel({
    interval: 7000
  });

  // $('ul#topnav li.dropdown').hover(
  //   function () {
  //     $(this)
  //       .find('.dropdown-menu')
  //       .stop(true, true)
  //       .delay(200)
  //       .fadeIn(500);
  //   },
  //   function () {
  //     $(this)
  //       .find('.dropdown-menu')
  //       .stop(true, true)
  //       .delay(200)
  //       .fadeOut(500);
  //   },
  // );

  const navbarToggler = document.querySelector(".navbar-toggler");
  const navbarCollapse = document.querySelector(".navbar-collapse");
  const minusToggle = document.querySelectorAll(".plus-minus-toggle");
  const dropCollapse = document.querySelectorAll(".dropdown-collapse");

  navbarToggler.addEventListener("click", () => {
    navbarToggler.classList.toggle("active");

    minusToggle.forEach(function(item) {
      item.classList.add("collapsed");
    });

    dropCollapse.forEach(function(item) {
      item.classList.remove("show");
    });
  });

  $(".dropdown-item").on("click", function() {
    navbarToggler.classList.remove("active");
    navbarCollapse.classList.remove("show");
  });

  document.addEventListener("DOMContentLoaded", () => {
    new Cocoen();
  });

  $(".table-responsive-stack")
    .find("th")
    .each(function(i) {
      $(`.table-responsive-stack td:nth-child(${i + 1})`).prepend(
        `<span class="table-responsive-stack-thead">${$(this).text()}:</span> `
      );
      $(".table-responsive-stack-thead").hide();
    });

  $(".table-responsive-stack").each(function() {
    const thCount = $(this).find("th").length;
    const rowGrow = `${100 / thCount}%`;
    // console.log(rowGrow);
    $(this)
      .find("th, td")
      .css("flex-basis", rowGrow);
  });

  // table stack

  function flexTable() {
    if ($(window).width() < 768) {
      $(".table-responsive-stack").each(function(i) {
        $(this)
          .find(".table-responsive-stack-thead")
          .show();
        $(this)
          .find("thead")
          .hide();
      });

      // window is less than 768px
    } else {
      $(".table-responsive-stack").each(function(i) {
        $(this)
          .find(".table-responsive-stack-thead")
          .hide();
        $(this)
          .find("thead")
          .show();
      });
    }
    // flextable
  }

  flexTable();

  window.onresize = function(event) {
    flexTable();
  };

  const $dropdownCollapse = $(".dropdown-collapse");

  $dropdownCollapse.on("show.bs.collapse", function() {
    $dropdownCollapse.filter(".show").collapse("hide");
    $(`[data-target="#${$(this).attr("id")}"]`).addClass("collapsed");
  });

  $dropdownCollapse.on("hide.bs.collapse", function() {
    $(`[data-target="#${$(this).attr("id")}"]`).removeClass("collapsed");
  });
};
