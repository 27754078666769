import twitterFetcher from 'twitter-fetcher';
import select2 from 'select2'; // /dist/js/select2

export default () => {
  //   twitterFetcher.fetch({
  //     profile: { screenName: 'adec_km' },
  //     domId: 'twitter-feeds',
  //     maxTweets: 10,
  //     enableLinks: true,
  //     showUser: true,
  //     showTime: false,
  //     showImages: false,
  //     lang: 'en',
  //     customCallback: (tweets) => {
  //       const $twitterFeeds = $('#twitter-feeds');
  //       const x = tweets.length;
  //       let n = 0;
  //       let html = '';

  //       while (n < tweets.length) {
  //         html += `<div class="carousel-item bg-mystic${n === 0 ? ' active' : ''}">${
  //           tweets[n]
  //         }</div>`;
  //         n++;
  //       }

  //       $twitterFeeds.html(html);
  //       $twitterFeeds.find('[data-scribe="element:avatar"]').remove();
  //       $twitterFeeds.find('.interact a').addClass('mr-2');
  //     },
  //   });

  // Loading array data
  const data = [
    { id: 0, text: 'enhancement' },
    { id: 1, text: 'bug' },
    { id: 2, text: 'duplicate' },
    { id: 3, text: 'invalid' },
    { id: 4, text: 'wontfix' },
  ];

  const $datatypelinks = $('#datatype-links .datatype-link');
  const $dataLinkSubmit = $('#datatype-link-submit');
  const $dataCenter = $('#datacenter');

  //   $(".select-data-array").select2({
  //     data
  //   });
  //   $(".select-data-array-selected").select2({
  //     data
  //   });

  // Enabled/Disabled
  //   $(".select-disabled").select2();
  //   $(".select-enable").on("click", () => {
  //     $(".select-disabled").prop("disabled", false);
  //     $(".select-disabled-multi").prop("disabled", false);
  //   });
  //   $(".select-disable").on("click", () => {
  //     $(".select-disabled").prop("disabled", true);
  //     $(".select-disabled-multi").prop("disabled", true);
  //   });

  function formatState(data, container) {
    if (data.element) {
      $(container).addClass($(data.element).attr('class'));
    }
    return data.text;
  }

  // Without Search
  $('.select-hide').select2({
    minimumResultsForSearch: Infinity,
  });

  function matrixLabel() {
    if ($(window).width() < 760) {
      $('#chained-set .select2-selection__rendered').text('I am a(n)');
      $('#datatype-links .select2-selection__rendered').text('that needs to');
    } else {
      $('#chained-set .select2-selection__rendered').text('-');
      $('#datatype-links .select2-selection__rendered').text('-');
    }
  }

  matrixLabel();

  window.addEventListener(
    'resize',
    (event) => {
      matrixLabel();
    },
    true,
  );

  // select Tags
  //   $(".select-tags").select2({
  //     tags: true
  //   });

  $dataCenter.on('change', function () {
    const $datatypelink = $(this.value);

    if (this.value && $datatypelink.length) {
      $datatypelinks.filter('.selected').removeClass('selected');
      $datatypelink.addClass('selected');
    }
  });

  //
  $dataLinkSubmit.on('click', function (e) {
    e.preventDefault();
    if ($('select').filter('.selected').value !== '#datatype-link-empty') {
      const $this = $(this);

      const $selected = $datatypelinks.filter('.selected');

      console.log($selected.val());

      $('.pnote').text('Please select dropdown.');

      if ($selected.val() && $selected.val() !== '#datatype-link-empty') {
        const win = window.open($selected.val(), '_parent');
        win.focus();

        $('.pnote').text('');
      }
    }
  });
};
