export default () => {
  const $recentUploads = $('#recent-uploads');

  $recentUploads.slick({
    autoplay: true,
    arrows: false,
    mobileFirst:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
    ]
  });

  $('#recent-uploads-nav')
    .on('click', '.btn:first-child', () => {
      $recentUploads.slick('slickPrev');
    })
    .on('click', '.btn + .btn', () => {
      $recentUploads.slick('slickNext');
    });
};
