export default () => {
  const $recentUploads = $("#accreditations-associations");

  $recentUploads.slick({
    autoplay: true,
    arrows: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  });

  $("#accreditations-associations-nav")
    .on("click", ".btn:first-child", () => {
      $recentUploads.slick("slickPrev");
    })
    .on("click", ".btn + .btn", () => {
      $recentUploads.slick("slickNext");
    });

  function visible(partial) {
    var $t = partial,
      $w = $(window),
      viewTop = $w.scrollTop(),
      viewBottom = viewTop + $w.height(),
      _top = $t.offset().top,
      _bottom = _top + $t.height(),
      compareTop = partial === true ? _bottom : _top,
      compareBottom = partial === true ? _top : _bottom;

    return (
      compareBottom <= viewBottom && compareTop >= viewTop && $t.is(":visible")
    );
  }

  function log(x) {
    console.log(x);
  }

  const counterOne = () => {
    $(window).scroll(function() {
      var countNum = $(".count-number");

      if (visible(countNum)) {
        if (countNum.hasClass("counter-loaded")) return;
        countNum.addClass("counter-loaded");

        countNum.each(function() {
          var $this = $(this);
          $({ Counter: 0 }).animate(
            { Counter: $this.text() },
            {
              duration: 2000,
              easing: "swing",
              step: function() {
                $this.text(Math.ceil(this.Counter));
              }
            }
          );
        });
      }
    });
  };

  counterOne();
};
