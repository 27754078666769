// bootstrap - remove or uncomment any unused script
// import 'bootstrap/js/dist/alert'
// import 'bootstrap/js/dist/button'
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
import "bootstrap/js/dist/tab";
// import 'bootstrap/js/dist/tooltip'
import "bootstrap/js/dist/util";
import "skrollr";

// Plugin
import "slick-carousel";
import "magnific-popup";
import "owl.carousel";
import "shufflejs";
import "cocoen";

// controllers
import common from "./controllers/common";
import aboutUs from "./controllers/about-us";
import home from "./controllers/home";
import resources from "./controllers/resources";
import projects from "./controllers/projects";
import istouchdevice from "./lib/istouchdevice";
import skrollr from "./common/skrollr";

const controllers = {
  "page-92": aboutUs,
  index: home,
  resources
};

$(() => {
  common();

  if (!istouchdevice()) {
    skrollr.init({
      forceHeight: false
    });

    $(window).on("load", () => {
      skrollr.get().refresh();
    });
  }

  $("[data-controller]").each((index, element) => {
    const controller = $(element).data("controller");

    if (typeof controllers[controller] === "function") {
      controllers[controller]();
    }
  });
});
